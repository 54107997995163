<script>
  import Header from "../home/Header.vue";
  import html2canvas from "html2canvas";
  import Iconic from "../../plugins/iconic/iconic.vue";

  export default {
    components: { Header, Iconic },
    data() {
      return {
        appHost: process.env.VUE_APP_HOST,
        personalData: [
          {
            key: "email",
            inputName: "Email",
            placeholder: "Email",
            type: "email",
            name: "email",
            value: "",
            validation: this.validate,
          },
          {
            key: "confirm-email",
            inputName: "Confirm Email",
            placeholder: "Confirm Email",
            type: "email",
            name: "email",
            value: "",
            validation: this.validate,
          },
          { key: "legal-name", inputName: "Legal Name", placeholder: "Enter legal name", type: "text", name: "legalName", value: "", validation: this.validate },
        ],
        reportingFor: false,
        ulrsReport: [
          { key: "url", placeholder: "Enter URL here", type: "text", value: "" },
          { key: "url", placeholder: "Enter URL here", type: "text", value: "" },
          { key: "url", placeholder: "Enter URL here", type: "text", value: "" },
        ],
        additionalInformation: "",
        digitalSignature: "",
        ticketNumber: "",
      };
    },
    computed: {
      dataToSet() {
        const data = {
          reportingFor: this.reportingFor,
          ulrsReport: this.ulrsReport.filter(({ value }) => value).map(({ value }) => ({ url: value })),
          additionalInformation: this.additionalInformation,
          digitalSignature: this.digitalSignature,
        };

        this.personalData.forEach(({ name, value }) => (data[name] = value));

        return data;
      },
    },
    methods: {
      validate($event) {
        this.$validate.validateInput($event, { tooltip: false });
      },
      validateInfo() {
        const element = document.querySelector(`.removal-view__info`);

        this.validate(element);

        return !element.classList.contains("data-error");
      },
      validateUrls() {
        const existUrlReporting = this.ulrsReport.some(({ value }) => !!value);
        const fmOptions = document.querySelectorAll(`.removal-view__url`);

        [].forEach.call(fmOptions, function (el) {
          el.classList[existUrlReporting ? "remove" : "add"]("data-error");
        });

        return existUrlReporting;
      },
      validateEqEmails() {
        const fmOptions = document.querySelectorAll(`#formRemoval input[type="email"]`);

        const data = new Set();

        [].forEach.call(fmOptions, function (el) {
          data.add(el.value);
        });

        const isEqEmails = data.size === 1;

        if (!isEqEmails) {
          [].forEach.call(fmOptions, function (el) {
            el.classList.add("data-error");
          });
        }

        return isEqEmails;
      },
      formatHtmlToRead(htmlToSet) {
        const divClone = htmlToSet.cloneNode(true);

        const inputs = divClone.getElementsByTagName("input");

        for (let input of inputs) {
          const textNode = document.createTextNode(input.value);
          input.parentNode.replaceChild(textNode, input);
        }

        return divClone.innerHTML;
      },
      async sendForm() {
        const isValidForm = this.$validate.validateForm(`#formRemoval`, { tooltip: false });
        const isValidUrls = this.validateUrls();
        const validateAditionInfo = this.validateInfo();

        if (!isValidForm || !isValidUrls || !validateAditionInfo || !this.reportingFor) {
          return;
        }

        const isEqEmails = this.validateEqEmails();

        if (!isEqEmails) {
          return;
        }
        this.ialert.go({ type: "loader" });

        const htmlToSet = this.$refs.formRemoval;
        const imageRequest = await this.htmlToImage2(htmlToSet);

        const body = {
          requestRemoval: this.dataToSet,
          imageRequest,
        };

        const { data } = await this.$api.post(`${process.env.VUE_APP_API_HOST}support/removal`, body);
        const { success, ticketNumber, message } = data;

        this.ialert.close();

        this.ticketNumber = ticketNumber;

        if (!success) {
          this.ialert.go({ title: "Error", message });
        }
      },
      async htmlToImage2(divMaqueta) {
        const canvas = await html2canvas(divMaqueta);

        return canvas.toDataURL("image/png");
      },
      goHome() {
        this.$router.push({ name: "home" });
      },
    },
  };
</script>

<template>
  <section class="removal-view">
    <Header />
    <div id="formRemoval" ref="formRemoval" style="display: flex; flex-direction: column; align-items: center; width: 100%; max-width: 662px; gap: 20px; background: #f9f8fd">
      <h1 style="font-size: 18px; color: #bd0909">REPORT ABUSIVE OR ILLEGAL CONTENT</h1>
      <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 15px; padding: 30px; border-radius: 12px; background: #ffffff">
        <p style="font-size: 15px">
          <a :href="`${appHost}/`" style="color: #bd0909; text-decoration: underline">My Fans 1A</a> takes all content removal requests seriously and our dedicated support team works around the clock
          to quickly process and remove content that violates our <a :href="`${appHost}/terms`" style="color: #bd0909; text-decoration: underline">Terms and services.</a>
        </p>
        <p style="font-size: 15px">Your report is completely confidential. When you report content, the user who posted the content will not see your name or any information about you.</p>
        <p style="font-size: 15px">Please complete the form below should you be the victim of, or come across content that you have personal knowledge of as constituting:</p>
        <ul style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 10px; list-style-type: disc; padding-left: 20px; font-size: 15px">
          <li style="font-size: 15px">Non-consensual production and/or distribution of your image (including but not limited to such things as: revenge porn, blackmail, exploitation)</li>
          <li style="font-size: 15px">Content that reveals personally identifiable information (including but not limited to such things as: name, address, phone number, IP address)</li>
          <li style="font-size: 15px">Otherwise abusive and/or illegal content.</li>
        </ul>
        <div v-for="(personal, idx) in personalData" :key="`${personal.key}-${idx}`" style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 5px">
          <label :for="`${personal.key}-${idx}`" style="padding: 0 10px">{{ personal.inputName }}</label>
          <input
            :id="`${personal.key}-${idx}`"
            :type="personal.type"
            :placeholder="personal.placeholder"
            v-model="personal.value"
            @blur="personal.validation"
            style="width: 100%; height: 40px; border-radius: 50px; border: 1px solid #d9d9d9; padding: 0 15px"
            required />
        </div>
        <div>
          <p style="font-size: 15px">You are reporting</p>
          <div style="display: flex; flex-direction: row; gap: 6px; padding-top: 2px">
            <input id="reportingFor" type="checkbox" v-model="reportingFor" style="background-color: #000; cursor: pointer" required />
            <label for="reportingFor" style="color: #555555; cursor: pointer">Abusive or Illegal Content</label>
          </div>
        </div>
        <p style="font-size: 15px">URLs of the content you are reporting</p>
        <div v-for="(data, idx) in ulrsReport" :key="`${data.key}-${idx}`" style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 5px">
          <input
            class="removal-view__url"
            :id="`${data.key}-${idx}`"
            :type="data.type"
            :placeholder="data.placeholder"
            v-model="data.value"
            @blur="validateUrls"
            style="width: 100%; height: 40px; border-radius: 50px; border: 1px solid #d9d9d9; padding: 0 15px" />
        </div>
        <p style="font-size: 15px">Additional Information</p>
        <p style="font-size: 15px; color: #555555">
          Please provide any additional information which might help us to resolve your request. If you are submitting a request on the behalf of someone else appearing in the content, please provide
          your association to this person.
        </p>
        <textarea
          class="removal-view__info"
          style="width: 100%; height: 112px; border-radius: 10px; border: 1px solid #d9d9d9; padding: 15px"
          placeholder="Enter Additional Information"
          @blur="validate"
          v-model="additionalInformation"
          required></textarea>
        <p style="font-size: 15px; color: #555555">Please be aware that abuse of this content removal request process hinders our team's ability to process valid and actionable removal requests.</p>
        <p style="font-size: 15px">Digital Signature</p>
        <p style="font-size: 15px; color: #555555">
          By typing your name in the field below, you guarantee that you are the person being named and represented on this form and that all provided information is accurate.
        </p>
        <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 5px">
          <label for="digitalSignature" style="padding: 0 10px">Legal Name</label>
          <input
            id="digitalSignature"
            type="text"
            placeholder="Enter legal name"
            style="width: 100%; height: 40px; border-radius: 50px; border: 1px solid #d9d9d9; padding: 0 15px"
            @blur="validate"
            v-model="digitalSignature"
            required />
        </div>

        <div style="display: flex; flex-direction: column; align-items: center; width: 100%">
          <button
            style="display: inline-block; width: 100%; max-width: 256px; padding: 10px 20px; outline: none; color: #ffffff; background: #bd0909; border-radius: 4pc; border: none; cursor: pointer"
            @click="sendForm">
            Send
          </button>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 15px; padding: 30px; border-radius: 12px; background: #ffffff">
        <h3 style="font-size: 15px; color: #bd0909">How we deal with complaints of illegal or non-consensual content</h3>
        <p style="font-size: 15px; color: #555555">Following receipt of your complaint of illegal or non-consensual Content under section 4 above:</p>
        <ul style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 10px; list-style-type: disc; padding-left: 20px; font-size: 15px; color: #555555">
          <li style="font-size: 15px">We will take such steps as we consider to be appropriate resolve your complaint within 7 business days.</li>
          <li style="font-size: 15px">If we require further information or documents from you, we will contact you to let you know.</li>
          <li style="font-size: 15px">We will in good faith investigate your complaint and take the following action:</li>
          <ul style="display: flex; flex-direction: column; align-items: flex-start; width: 100%; gap: 10px; list-style-type: disc; padding-left: 20px; font-size: 15px">
            <li style="font-size: 15px">
              If we are satisfied that the Content is unlawful or non-consensual, we will immediately remove such Content, and we will notify you of our decision by email or other electronic message.
            </li>
            <li style="font-size: 15px">If we are satisfied that the Content is not unlawful or non-consensual, we will notify you of our decision by email or other electronic message.</li>
            <li style="font-size: 15px">Any dispute regarding our decision on non-consensual content will be submitted by us to a neutral arbitration association at our expense.</li>
          </ul>
        </ul>
      </div>
    </div>
    <div class="ialert" v-if="ticketNumber">
      <div id="ialert-card" class="ialert-card removal-view__alert">
        <div class="removal-view__alertHeader">
          <iconic class="removal-view__alertIcon" name="success" />
          <p class="removal-view__alertTitle">Request created successfully</p>
        </div>
        <div class="removal-view__alerBody">
          <p class="removal-view__reId">
            The following is the tracking number of your request <strong class="removal-view__reNumber">#{{ ticketNumber }}.</strong>
          </p>
          <p class="removal-view__promise">We will take such steps as we consider to be appropriate resolve your complaint within 7 business days.</p>
        </div>
        <button class="removal-view__btnAgree" @click="goHome">Agree</button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .removal-view {
    @include Flex(column, flex-start);
    width: 100%;
    padding: 65px $mpadding $mpadding $mpadding;
    color: #1e1e1e;
    font-size: 15px;
    .data-error {
      border-color: $primary_color !important;
    }
    &__alert {
      @include Flex(column, flex-start);
      padding: 20px;
      gap: 20px;
    }
    &__alertHeader {
      @include Flex(row, space-between);
      gap: 10px;
    }
    &__alertIcon {
      font-size: 18px;
    }
    &__alertTitle {
      color: $primary_color;
      font-family: $third_font_bold;
      font-size: 18px;
    }
    &__alerBody {
      @include Flex(column, flex-start);
      gap: 27px;
      text-align: center;
      font-size: 15px;
      color: #555555;
    }
    &__reId {
      color: #000;
    }
    &__reNumber {
      font-size: 15px;
    }
    &__btnAgree {
      padding: 5px 25px;
      outline: none;
      color: #ffffff;
      background: #bd0909;
      border-radius: 4pc;
      border: none;
      cursor: pointer;
    }
  }
</style>
